<template>
  <b-card-code>
    <b-card-body>
        <h2 class="text-primary">
            Base data
        </h2>
        <b-card-text class="mb-2">
            <span>Vivamus in suscipit nisl. Praesent rutrum vestibulum suscipit. Nullam at lacus id mauris fringilla imperdiet at in purus. Vivamus pellentesque ipsum in ultrices porttitor.</span>
        </b-card-text>
    </b-card-body>
    <b-form>
        <b-row>
            <b-col
            cols="12"
            offset="1"
            md="8"
            >
            <b-form-group
                label="First Name"
                label-for="user-first-name"
            >
                <b-form-input
                id="user-first-name"
                placeholder="First Name"
                />
            </b-form-group>
            </b-col>

            <b-col
            cols="12"
            md="2"
            >
            <b-form-group
                label-for="currency"
                label="Currency"
                >
                <v-select
                    id="currency"
                    v-model="selectedCurrency"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :selectable="option => ! option.value.includes('select_currency')"
                    :options="currencyName"
                    label="text"
                />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col
            cols="12"
            offset="1"
            md="10"
            >
                <label for="textarea-default">Description</label>
                <b-form-textarea
                id="textarea-default"
                placeholder="Descrição teste"
                rows="3"
                />
            </b-col>
        </b-row>

        <b-row>
            <b-col
            cols="12"
            offset="1"
            md="10"
            >
                <div class="content-inline-spacing">
                    <b-form-radio
                        v-model="Selected"
                        name="some-radios"
                        value="specificDate"
                    >
                        Specific date
                    </b-form-radio>
                    <b-form-radio
                        v-model="Selected"
                        name="some-radios"
                        value="specificQuarter"
                    >
                        Specific quarter
                    </b-form-radio>
                </div>
            </b-col>
        </b-row>

        <b-row style="padding-top: 1.5rem;">
            <b-col
            cols="12"
            offset="1"
            md="1"
            >
                <h6 class="text-primary">
                    From:
                </h6>
            </b-col>

            <b-col
            cols="12"
            md="3"
            >
                <label for="datepicker">Year</label>
                <b-form-datepicker
                    id="datepicker"
                    v-model="value"
                    class="mb-1"
                />
            </b-col>

            <b-col
            cols="12"
            md="1"
            >
                <h6 class="text-primary">
                    To:
                </h6>
            </b-col>
            <b-col
            cols="12"
            md="3"
            >
                <label for="datepicker-2">Year</label>
                <b-form-datepicker
                    id="datepicker-2"
                    v-model="value"
                    class="mb-1"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col
            cols="12"
            offset="2"
            md="3"
            >
                <b-form-group
                label="Month"
                label-for="month"
                >
                    <v-select
                        id="month"
                        v-model="selectedMonth"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="monthName"
                        :selectable="option => ! option.value.includes('select_month')"
                        label="text"
                    />
                </b-form-group>
            </b-col>
            <b-col
            cols="12"
            offset="1"
            md="3"
            >
                <b-form-group
                label="Month"
                label-for="month"
                >
                    <v-select
                        id="month"
                        v-model="selectedMonth"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="monthName"
                        :selectable="option => ! option.value.includes('select_month')"
                        label="text"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row style="padding-top: 1.5rem;">
            <b-col 
                cols="12"
                offset="1"
                md="10"
            >
                <b-button
                    variant="dark"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                    Continue
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                </b-button>
            </b-col>
        </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BButton, BFormTextarea, BFormRadio, BRow, BCol, BFormGroup, BFormInput, BForm, BCardText, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'

export default {
  components: {
    BCardCode,
    BButton,
    BFormTextarea,
    BFormRadio,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCardText,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      Selected: 'specificDate',
      Selected1: 'specificQuarter',
      selectedCurrency: 'EUR',
      selectedMonth: 'January',
      monthName: [
        // { value: 'select_country', text: 'Select Country' },
        { value: 'January', text: 'January' },
        { value: 'February', text: 'February' },
        { value: 'March', text: 'March' },
        { value: 'April', text: 'April' },
        { value: 'May', text: 'May' },
        { value: 'June', text: 'June' },
        { value: 'July', text: 'July' },
        { value: 'Augusty', text: 'Augusty' },
        { value: 'September', text: 'September' },
        { value: 'October', text: 'October' },
        { value: 'November', text: 'November' },
        { value: 'December', text: 'December' },
      ],
      currencyName: [
        // { value: 'select_currency', text: 'Select Currency' },
        { value: 'EU', text: 'EUR' },
        { value: 'USA', text: 'USD' },
      ],
    }
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
